




































import {
  Component, PropSync, Vue,
} from 'vue-property-decorator';
import LinkStatusTransferRequestedViewModel
  from '@/vue-app/view-models/components/strategy-moderate-wealth/link-status-moderate/link-status-transfer-requested-view-model';

@Component({ name: 'LinkStatusTransferRequested' })
export default class LinkStatusTransferRequested extends Vue {
  @PropSync('goalName', { type: String, required: true })
  synced_goal_name!: string;

  link_status_transfer_requested = Vue.observable(
    new LinkStatusTransferRequestedViewModel(this),
  );
}
